.gallery {
  .main-image {
    text-align: center;
    margin-bottom: 15px;
    height: 400px;

    img {
      max-width: 100%;
      height: 100%;
    }
  }
  .images {
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: center;
      li {
        background-size: cover;
        width: 100px;
        height: 100px;
        display: block;
        margin-right: 15px;
      }
    }
  }
}

.nav {
  li {
    list-style: none;
    display: inline-block;
    margin-right: 15px;

    a {
      color: var(--surface-500);
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

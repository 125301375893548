header {
  margin-bottom: 25px;
  .logo {
    img {
      width: 160px;
    }
  }

  .search {
    padding-top: 25px;
  }

  .navigation {
    padding-top: 25px;
  }

  .tools {
    padding-top: 38px;

    .pi {
      margin-right: 10px;
    }
  }
}

.categories-banner {
  margin-top: 75px;

  h3 {
    margin-bottom: 20px;
    color: var(--surface-700);
  }

  .category {
    min-height: 100px;
    border-radius: 14px;
    text-align: center;
    display: flex;
    background-color: var(--surface-700);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &-icon {
      .pi {
        font-size: 2em;
        margin-bottom: 10px;
      }
    }

    &-name {
      font-size: 1em;
    }
  }
}

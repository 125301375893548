footer {
  background-color: var(--yellow-100);
  min-height: 50px;
  padding: 10px 25px;
  width: 100%;
  margin-top: 25px;

  .footer-wrapper {
    align-items: center;

    .social {
      margin-top: 15px;
      text-align: right;
      .nav {
        justify-content: flex-end;
      }
    }
  }
}

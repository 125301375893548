// Base Config
@import '~normalize.css/normalize.css';
@import './base/colors.scss';
@import './base/fonts.scss';
@import './base/mixins.scss';

//Libraries
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeflex/primeflex.scss';
@import '~animate.css/animate.css';

@import './base/configuration.scss';

//Shared
@import './apps/shop/shared/header.scss';
@import './apps/shop/shared/footer.scss';
@import './apps/shop/shared/nav.scss';

//Pages
@import './apps/shop/pages/home-page.scss';
@import './apps/shop/pages/products-list-page.scss';

//Libs
@import './libs/products/products.scss';
@import './libs/orders/orders.scss';
@import './libs/users/users.scss';
@import './libs/ui/ui.scss';

.container {
  width: 1200px;
  padding: 15px;
  margin: 0 auto;
  background-color: var(--surface-100);
}
